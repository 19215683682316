// components/ui/Section.js
import React from 'react';
import styles from './Section.module.css';

function Section({ 
  children, 
  id, 
  className = '', 
  backgroundColor = 'light',
  spacing = 'md'
}) {
  const sectionClasses = [
    styles.section,
    styles[`bg-${backgroundColor}`],
    styles[`spacing-${spacing}`],
    className
  ].filter(Boolean).join(' ');

  return (
    <section id={id} className={sectionClasses}>
      <div className={styles.container}>
        {children}
      </div>
    </section>
  );
}

export default Section;