// components/Products.js
import React from 'react';
import styles from './Products.module.css';
import ProductCard from './ProductCard';
import Section from './ui/Section';

function Products() {
  const products = [
    {
      title: "Лендинги",
      price: "От 15 000 ₽",
      term: "Срок: от 14 дней"
    },
    {
      title: "Многостраничные",
      price: "От 30 000 ₽",
      term: "Срок: от 30 дней"
    },
    {
      title: "Сайты с интеграциями",
      price: "От 45 000 ₽",
      term: "Срок: от 45 дней"
    },
    {
      title: "Редизайн",
      price: "От 20 000 ₽",
      term: "Срок: от 21 дня"
    }
  ];

  return (
    <Section id="products" backgroundColor="light" spacing="lg">
      <h2 className={styles.productsTitle}>Мои услуги</h2>
      <div className={styles.productsContainer}>
        {products.map((product, index) => (
          <ProductCard 
            key={index}
            title={product.title}
            price={product.price}
            term={product.term}
          />
        ))}
      </div>
    </Section>
  );
}

export default Products;