// components/Services.js
import React from 'react';
import styles from './Services.module.css';
import ServiceCard from './ServiceCard';
import Section from './ui/Section';

function Services() {
  const services = [
    {
      icon: "🔍",
      title: "Создам современный сайт",
      description: "Разработка интуитивно понятных сайтов, которыми легко пользоваться клиентам"
    },
    {
      icon: "🌐",
      title: "Расширю географию бизнеса",
      description: "Помогу привлечь клиентов из других регионов через эффективное онлайн-присутствие"
    },
    {
      icon: "👁️",
      title: "Сделаю ваш бренд заметным",
      description: "Создание привлекательного цифрового представительства вашего бизнеса"
    },
    {
      icon: "🔄",
      title: "Помогу взглянуть на бизнес по-новому",
      description: "Задам правильные вопросы, которые помогут увидеть и устранить скрытые проблемы в работе вашего бизнеса"
    }
  ];

  return (
    <Section id="services" backgroundColor="grey" spacing="lg">
      <h2 className={styles.servicesTitle}>Чем я могу помочь вашему бизнесу</h2>
      <div className={styles.servicesContainer}>
        {services.map((service, index) => (
          <ServiceCard 
            key={index}
            icon={service.icon}
            title={service.title}
            description={service.description}
          />
        ))}
      </div>
    </Section>
  );
}

export default Services;