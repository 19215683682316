// components/Portfolio.js
import React from 'react';
import styles from './Portfolio.module.css';
import PortfolioItem from './PortfolioItem';
import Section from './ui/Section';
import Button from './ui/Button';

function Portfolio() {
  const portfolioItems = [
    { title: "Juldem.ru", image: "placeholder" },
    { title: "Garrycroony.ru", image: "placeholder" },
    { title: "\"Моя радость\"", image: "placeholder" }
  ];

  return (
    <Section id="portfolio" backgroundColor="light" spacing="lg">
      <h2 className={styles.portfolioTitle}>Моё портфолио</h2>
      <div className={styles.portfolioContainer}>
        {portfolioItems.map((item, index) => (
          <PortfolioItem 
            key={index}
            title={item.title}
            image={item.image}
          />
        ))}
      </div>
      <div className={styles.portfolioMore}>
        <Button variant="outline">Смотреть все работы</Button>
      </div>
    </Section>
  );
}

export default Portfolio;