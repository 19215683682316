// components/Footer.js
import React from 'react';
import styles from './Footer.module.css';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerColumn}>
          <div className={styles.footerLogo}>ЛОГО</div>
          <p className={styles.footerCopyright}>© 2025 Создание сайтов для малого бизнеса</p>
        </div>
        
        <div className={styles.footerColumn}>
          <p className={styles.footerLink}>Услуги</p>
          <p className={styles.footerLink}>Портфолио</p>
          <p className={styles.footerLink}>Контакты</p>
        </div>
        
        <div className={styles.footerColumn}>
          <p className={styles.footerContact}>+7 (XXX) XXX-XX-XX</p>
          <p className={styles.footerContact}>info@example.com</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;