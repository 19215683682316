// App.js
import React from 'react';
import styles from './App.module.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Products from './components/Products';
import Stages from './components/Stages';
import Portfolio from './components/Portfolio';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';

function App() {
  return (
    <div className={styles.App}>
      <Header />
      <main className={styles.main}>
        <Hero />
        <Services />
        <Products />
        <Stages />
        <Portfolio />
        <ContactUs />
      </main>
      <Footer />
    </div>
  );
}

export default App;