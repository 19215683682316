// components/PortfolioItem.js
import React from 'react';
import styles from './PortfolioItem.module.css';

function PortfolioItem({ title, image }) {
  return (
    <div className={styles.portfolioItem}>
      <div className={styles.portfolioImage}>
        <div className={styles.placeholder}>[скриншот сайта]</div>
      </div>
      <h3 className={styles.portfolioItemTitle}>{title}</h3>
    </div>
  );
}

export default PortfolioItem;