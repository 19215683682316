// components/Hero.js
import React from 'react';
import styles from './Hero.module.css';
import Button from './ui/Button';
import heroBg from '../assets/images/hero-bg.webp';

function Hero() {
  return (
    <section className={styles.hero} id="hero">
      <div 
        className={styles.heroBackgroundImage} 
        style={{backgroundImage: `url(${heroBg})`}}
      >
        {/* Фоновое изображение добавлено через inline стиль */}
      </div>
      <div className={styles.heroOverlay}></div>
      <div className={styles.heroContent}>
        <h1 className={styles.heroTitle}>Сайты для малого{'\u00A0'}бизнеса с{'\u00A0'}ИИ{'\u2011'}подходом</h1>
        <h2 className={styles.heroSubtitle}>быстрее, эффективнее, дешевле</h2>
        <Button variant="primary" size="lg">Обсудить проект</Button>
      </div>
    </section>
  );
}

export default Hero;