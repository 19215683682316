// components/Header.js
import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';
import Button from './ui/Button';
// Импортируем оба логотипа
import logoBig from '../assets/images/logo-big.png';
import logoSmall from '../assets/images/logo-small.png';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  
  // Определяем, какой логотип использовать в зависимости от размера экрана
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768); // 768px - это breakpoint для мобильных устройств
    };
    
    // Проверяем размер экрана при монтировании компонента
    checkScreenSize();
    
    // Добавляем слушатель события изменения размера окна
    window.addEventListener('resize', checkScreenSize);
    
    // Очищаем слушатель при размонтировании компонента
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);
  
  // Отслеживаем скролл для изменения стиля хедера
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    // Вызываем один раз при монтировании
    handleScroll();
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  // Предотвращаем скролл на мобильных при открытом меню
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [menuOpen]);
  
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className={`${styles.header} ${scrolled ? styles.scrolled : ''}`}>
      <div className={styles.logo}>
        <img 
          src={isMobile ? logoSmall : logoBig} 
          alt="Логотип компании" 
          className={styles.logoImage} 
        />
      </div>
      
      <div 
        className={`${styles.menuToggle} ${menuOpen ? styles.active : ''}`} 
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      
      <div 
        className={`${styles.navBackdrop} ${menuOpen ? styles.active : ''}`} 
        onClick={closeMenu}
      ></div>
      
      <nav className={`${styles.navigation} ${menuOpen ? styles.active : ''}`}>
        <a href="#hero" className={styles.navLink} onClick={closeMenu}>Главная</a>
        <a href="#services" className={styles.navLink} onClick={closeMenu}>Услуги</a>
        <a href="#stages" className={styles.navLink} onClick={closeMenu}>Этапы работ</a>
        <a href="#portfolio" className={styles.navLink} onClick={closeMenu}>Портфолио</a>
        <a href="#about" className={styles.navLink} onClick={closeMenu}>Обо мне</a>
        <a href="#blog" className={styles.navLink} onClick={closeMenu}>Блог</a>
        <a href="#contact" className={styles.navLink} onClick={closeMenu}>Контакты</a>
        
        {/* Кнопка CTA только для мобильного меню */}
        <div className={styles.mobileOnly}>
          <Button variant="primary" onClick={closeMenu}>Заказать сайт</Button>
        </div>
      </nav>
      
      {/* Кнопка CTA для десктопа */}
      <div className={styles.desktopOnly}>
        <Button variant="primary" size="sm">Заказать сайт</Button>
      </div>
    </header>
  );
}

export default Header;