// components/ContactUs.js
import React from 'react';
import styles from './ContactUs.module.css';
import Button from './ui/Button';
import Section from './ui/Section';

function ContactUs() {
  return (
    <Section id="contact" spacing="lg">
      <div className={styles.contactUs}>
        <h2 className={styles.contactTitle}>Готовы обсудить ваш проект?</h2>
        <Button variant="primary" size="lg">Оставить заявку</Button>
      </div>
    </Section>
  );
}

export default ContactUs;