// components/ProductCard.js
import React from 'react';
import styles from './ProductCard.module.css';
import Button from './ui/Button';

function ProductCard({ title, price, term }) {
  return (
    <div className={styles.productCard}>
      <div className={styles.productHeader}>
        <h3 className={styles.productTitle}>{title}</h3>
      </div>
      <div className={styles.productDetails}>
        <p className={styles.productPrice}>{price}</p>
        <p className={styles.productTerm}>{term}</p>
        <Button variant="outline" size="sm">Подробнее</Button>
      </div>
    </div>
  );
}

export default ProductCard;