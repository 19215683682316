// components/ui/Button.js
import React from 'react';
import styles from './Button.module.css';

function Button({ 
  children, 
  variant = 'primary', 
  size = 'md',
  onClick, 
  className, 
  type = 'button',
  fullWidth = false,
  disabled = false
}) {
  const buttonClasses = [
    styles.button,
    styles[variant],
    styles[size],
    fullWidth ? styles.fullWidth : '',
    className || ''
  ].filter(Boolean).join(' ');

  return (
    <button 
      className={buttonClasses}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;