// components/Stages.js
import React from 'react';
import styles from './Stages.module.css';
import Section from './ui/Section';
import Button from './ui/Button';

const StageItem = ({ number, title, days, description, resultList, price, payment, isLast = false }) => {
  return (
    <div className={styles.stageItem}>
      <div className={styles.stageHeader}>
        <div className={`${styles.stageNumber} ${isLast ? styles.lastStageNumber : ''}`}>{number}</div>
        <h3 className={styles.stageTitle}>{title}</h3>
        <p className={styles.stageDays}>{days}</p>
      </div>
      
      <div className={styles.stageContent}>
        <div className={styles.stageDescription}>
          <h4 className={styles.stageSubtitle}>Что делаю я:</h4>
          <ul className={styles.stageList}>
            {description.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        
        <div className={styles.stageResults}>
          <h4 className={styles.stageSubtitle}>Что получаете вы:</h4>
          <ul className={styles.stageList}>
            {resultList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        
        <div className={styles.stagePrice}>
          <p className={styles.priceLarge}>{price}</p>
          <p className={styles.pricePayment}>{payment}</p>
        </div>
      </div>
    </div>
  );
};

function Stages() {
  const stages = [
    {
      number: 1,
      title: 'Знакомство и анализ',
      days: '2-3 дня',
      description: ['Анализирую цели и потребности', 'Изучаю конкурентов', 'Составляю техническое задание'],
      resultList: ['Документ с описанием проекта', 'План работы', 'Рекомендации по реализации'],
      price: '3 000 - 5 000 ₽',
      payment: '100% предоплата'
    },
    {
      number: 2,
      title: 'Прототипирование',
      days: '5-7 дней',
      description: ['Разрабатываю структуру страниц', 'Создаю wireframes', 'Определяю пользовательские сценарии'],
      resultList: ['Интерактивный прототип', 'Схема навигации', 'Утвержденная структура'],
      price: 'Входит в стоимость дизайна',
      payment: ''
    },
    {
      number: 3,
      title: 'Дизайн сайта',
      days: '5-7 дней',
      description: ['Создаю дизайн-макеты', 'Разрабатываю адаптивный дизайн', 'До 2 правок бесплатно'],
      resultList: ['Готовые макеты', 'Мобильная версия дизайна', 'Возможность внесения корректировок'],
      price: '10 000 - 15 000 ₽',
      payment: '50% + 50%'
    },
    {
      number: 4,
      title: 'Верстка и программирование',
      days: '7-14 дней',
      description: ['Верстаю страницы', 'Обеспечиваю адаптивность', 'Программирую функционал'],
      resultList: ['Рабочий сайт на тестовом домене', 'Полностью адаптивная версия', 'Настроенные формы обратной связи'],
      price: '15 000 - 25 000 ₽',
      payment: '50% + 50%'
    },
    {
      number: 5,
      title: 'Наполнение контентом',
      days: '3-5 дней',
      description: ['Размещаю контент', 'Оптимизирую изображения', 'Настраиваю базовое SEO'],
      resultList: ['Готовый сайт с контентом', 'SEO-оптимизация', 'Проверенный материал'],
      price: '5 000 - 8 000 ₽',
      payment: '100% после'
    },
    {
      number: 6,
      title: 'Тестирование',
      days: '2-3 дня',
      description: ['Проверяю работу всех элементов', 'Тестирую функционал в разных браузерах', 'Оптимизирую скорость'],
      resultList: ['Полностью проверенный сайт', 'Отчет о тестировании', 'Устраненные ошибки'],
      price: 'Входит в стоимость запуска',
      payment: ''
    },
    {
      number: 7,
      title: 'Запуск',
      days: '2-3 дня',
      description: ['Переношу сайт на хостинг', 'Настраиваю аналитику', 'Провожу финальное тестирование'],
      resultList: ['Функционирующий сайт', 'Руководство пользователя', 'Настройка аналитики'],
      price: '5 000 - 7 000 ₽',
      payment: '100% после запуска'
    },
    {
      number: 8,
      title: 'Техническая поддержка',
      days: 'постоянно',
      description: [
        'Обеспечиваю бесперебойную работу', 
        'Резервное копирование данных', 
        'Обновление CMS и плагинов',
        'Решение технических проблем'
      ],
      resultList: [
        'Стабильно работающий сайт', 
        'Оперативное решение проблем', 
        'Регулярные обновления безопасности',
        'Первый месяц после запуска бесплатно'
      ],
      price: 'от 3 000 ₽/месяц',
      payment: 'Индивидуальный тариф',
      isLast: true
    }
  ];

  const packages = [
    {
      title: 'Лендинг',
      price: 'от 38 000 ₽',
      term: 'Срок: от 19 дней'
    },
    {
      title: 'Корпоративный',
      price: 'от 50 000 ₽',
      term: 'Срок: от 25 дней'
    },
    {
      title: 'Интернет-магазин',
      price: 'от 70 000 ₽',
      term: 'Срок: от 35 дней'
    }
  ];

  return (
    <Section id="stages" backgroundColor="light" spacing="lg">
      <h2 className={styles.stagesTitle}>Этапы и примерная стоимость работ</h2>
      
      <div className={styles.stagesContainer}>
        {stages.map((stage, index) => (
          <StageItem 
            key={index}
            number={stage.number}
            title={stage.title}
            days={stage.days}
            description={stage.description}
            resultList={stage.resultList}
            price={stage.price}
            payment={stage.payment}
            isLast={stage.isLast}
          />
        ))}
      </div>
      
      <div className={styles.packagePricing}>
        <h3 className={styles.packageTitle}>Общая стоимость разработки сайта</h3>
        <div className={styles.packageContainer}>
          {packages.map((pkg, index) => (
            <div key={index} className={styles.packageCard}>
              <h4 className={styles.packageName}>{pkg.title}</h4>
              <p className={styles.packagePrice}>{pkg.price}</p>
              <p className={styles.packageTerm}>{pkg.term}</p>
              <Button variant="primary" size="sm" className={styles.packageButton}>Заказать</Button>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default Stages;